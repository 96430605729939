import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import history from 'utils/history';
import App from 'containers/App';
import ErrorBoundry from 'containers/ErrorBoundary';
import configureStore from 'store';
import * as serviceWorker from 'serviceWorker';
import './translations';
import '../src/styles/main.scss';
import ErrorHandler from './services/ErrorHandler';

const initialState = {};
const store = configureStore(initialState, history);

const MOUNT_NODE = document.getElementById('root');

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <ErrorHandler>
        <ConnectedRouter history={history}>
          <ErrorBoundry>
            <App />
          </ErrorBoundry>
        </ConnectedRouter>
      </ErrorHandler>
    </Provider>,
    MOUNT_NODE
  );
};

if (module.hot) {
  module.hot.accept(['translations', 'containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render();
  });
}

render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

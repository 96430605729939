import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../store/snackbar/actions';
import httpService from './HttpService';
import { logoutSaga } from '../store/auth/actions';
import { getErrorMessage } from '../helpers';
import { setErrorHandlerInitialized } from '../store/errorHandler/actions';

function ErrorHandler(props) {
  const dispatch = useDispatch();

  const handleError = async () => {
    httpService.addResponseInterceptors(
      (response) => response.data,
      (err) => {
        err = err instanceof Error ? { err }?.err?.response : err;
        if (err) {
          if (err?.data instanceof ArrayBuffer) {
            err.message = JSON.parse(
              new TextDecoder().decode(err?.data)
            ).message;
            dispatch(
              setSnackbar({
                open: true,
                severity: 'error',
                message: getErrorMessage(err),
              })
            );
          } else {
            if (err.status === 401) {
              dispatch(
                setSnackbar({
                  open: true,
                  severity: 'error',
                  message: getErrorMessage(err),
                })
              );
              dispatch(logoutSaga());
            } else if (err.status === 400 || err.status === 500) {
              dispatch(
                setSnackbar({
                  open: true,
                  severity: 'error',
                  message: getErrorMessage(err),
                })
              );
            } else {
              throw err;
            }
          }
        } else {
          dispatch(
            setSnackbar({
              open: true,
              severity: 'error',
              message: 'Something went wrong!',
            })
          );
        }
      }
    );
    dispatch(setErrorHandlerInitialized(true));
  };

  useEffect(() => {
    handleError();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return props.children;
}

export default ErrorHandler;

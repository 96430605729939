import produce from 'immer';

import { SET_ERROR_HANDLER_INITIALIZED } from './actionTypes';

export const initialState = {
  errorHandlerInitialized: false,
};

/* eslint-disable default-case */

const errorHandlerReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case SET_ERROR_HANDLER_INITIALIZED:
        draft.errorHandlerInitialized = payload;
        break;
    }
  });

export default errorHandlerReducer;

import {
  GET_GROUP_SAGA,
  GET_GROUPS_SAGA,
  SET_GROUP,
  SET_GROUPS,
} from './actionTypes';

export function getGroupsSaga() {
  return {
    type: GET_GROUPS_SAGA,
  };
}

export function setGroups(payload) {
  return {
    type: SET_GROUPS,
    payload,
  };
}

export function getGroupSaga(groupId) {
  return {
    type: GET_GROUP_SAGA,
    groupId,
  };
}

export function setGroup(payload) {
  return {
    type: SET_GROUP,
    payload,
  };
}

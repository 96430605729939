import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import {
  DASHBOARD,
  LOGIN,
  REGISTER,
  FORGOT_PASSWORD,
  VERIFY_ACCOUNT,
  RESET_PASSWORD,
  GROUP_LIST,
  SINGLE_GROUP,
  SINGLE_WORKFLOW,
  REDIRECT_TO_WORKFLOW,
} from 'routes';
import PrivateRoute from 'containers/PrivateRoute';
import PublicRoute from 'containers/PublicRoute';
import Dashboard from 'containers/Dashboard/Loadable';
import LoginPage from 'containers/LoginPage/Loadable';
import RegisterPage from 'containers/RegisterPage/Loadable';
import NotFoundPage from 'containers/NotFoundPage/Loadable';
import ForgotPasswordPage from 'containers/ForgotPasswordPage/Loadable';
import VerifyAccountPage from 'containers/VerifyAccountPage/Loadable';
import ResetPasswordPage from 'containers/ResetPasswordPage/Loadable';
import GroupListPage from 'containers/GroupListPage/Loadable';
import SingleGroupPage from 'containers/SingleGroupPage/Loadable';
import SingleWorkflowPage from 'containers/SingleWorkflowPage/Loadable';
import RedirectToWorkflowPage from 'containers/RedirectToWorkflowPage/Loadable';

export default function Routes() {
  return (
    <Switch>
      <PublicRoute exact path={LOGIN} component={LoginPage} />
      <PublicRoute exact path={REGISTER} component={RegisterPage} />
      <PublicRoute exact path={VERIFY_ACCOUNT} component={VerifyAccountPage} />
      <PublicRoute
        exact
        path={REDIRECT_TO_WORKFLOW}
        component={RedirectToWorkflowPage}
      />
      <PublicRoute
        exact
        path={FORGOT_PASSWORD}
        component={ForgotPasswordPage}
      />
      <PublicRoute exact path={RESET_PASSWORD} component={ResetPasswordPage} />
      <PrivateRoute exact path={DASHBOARD} component={Dashboard} />
      <PrivateRoute exact path={GROUP_LIST} component={GroupListPage} />
      <PrivateRoute exact path={SINGLE_GROUP} component={SingleGroupPage} />
      <PrivateRoute
        exact
        path={SINGLE_WORKFLOW}
        component={SingleWorkflowPage}
      />
      <Route exact path="/">
        <Redirect to={DASHBOARD} />
      </Route>
      <Route path="*" component={NotFoundPage} />
    </Switch>
  );
}

import produce from 'immer';

import {
  SET_TEST_RESULTS_DATA,
  SET_WORKFLOW,
  SET_WORKFLOW_RESULTS_DATA,
} from './actionTypes';

export const initialState = {
  testResultsData: null,
  workflowResultData: null,
  workflow: null,
};

/* eslint-disable default-case */

const workflowReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case SET_TEST_RESULTS_DATA:
        draft.testResultsData = payload;
        break;

      case SET_WORKFLOW_RESULTS_DATA:
        draft.workflowResultData = payload;
        break;

      case SET_WORKFLOW:
        draft.workflow = payload;
        break;
    }
  });

export default workflowReducer;

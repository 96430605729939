export const HTTP_STATUS_CODES = {
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  SERVER_ERROR: 500,
  VALIDATION_FAILED: 422,
};

export const HTTP_METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

export const colors = {
  white: '#ffffff',
  black: '#000000',
  grayLight: '#F0F0F0',
  primaryColor: '#033e7e',
  backgroundColor: '#f7f7f7',
  warning: '#BD3547',
};

export const WORKFLOW_ATTEMPT_STATUS = {
  STARTED: 'started',
  COMPLETED: 'completed',
};

export const LESSON_TYPES = {
  PDF: '0',
  VIDEO: '1',
  AUDIO: '2',
  HTML: '3',
};

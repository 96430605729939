import jwtDecode from 'jwt-decode';

import httpService from './HttpService';
import { HTTP_METHODS, HTTP_STATUS_CODES } from 'consts';
import { getItem, setItem, removeItem } from 'utils/localStorage';
import { helperService } from './HelperService';

const ROUTES = {
  LOGIN: '/auth/user-portal-login',
  ME: '/users/me',
  LOGOUT: '/auth/logout',
  TOKEN_REFRESH: '/auth/refresh',
  VERIFY_EMAIL: '/auth/verify-account',
  FORGOT_PASSWORD: '/auth/forgot-password',
  RESET_PASSWORD: '/auth/reset-password',
  REGISTER_USER: '/auth/register',
};

class AuthService {
  constructor(httpService) {
    this.httpService = httpService;
    this.init();
  }

  init = () => {
    this.setAuthToken(this.getAccessToken());
    this.httpService.addRequestInterceptor(this.checkTokenExpiration);
    this.httpService.addResponseInterceptors(
      this.handleSuccessResponse,
      this.handleErrorResponse
    );
  };

  getAccessToken = () => {
    return getItem('token');
  };

  setAuthToken = (token) => {
    if (token) {
      setItem('token', token);

      this.httpService.attachHeaders({
        Authorization: `Bearer ${token}`,
      });
    }
  };

  refreshToken = async (prevToken) => {
    const { accessToken: newToken } = await this.httpService.request({
      url: ROUTES.TOKEN_REFRESH,
      method: HTTP_METHODS.POST,
      headers: {
        Authorization: `Bearer ${prevToken}`,
      },
    });

    this.setAuthToken(newToken);

    return newToken;
  };

  login = async (data) => {
    const res = await this.httpService.request({
      url: ROUTES.LOGIN,
      method: HTTP_METHODS.POST,
      data,
    });

    this.setAuthToken(res.authorization.token);

    return res;
  };

  registerUser = async (data) => {
    const res = await this.httpService.request({
      url: ROUTES.REGISTER_USER,
      method: HTTP_METHODS.POST,
      data,
    });

    this.setAuthToken(res.access_token);

    return res;
  };

  verifyAccount = async (data) => {
    const res = await this.httpService.request({
      url: ROUTES.VERIFY_EMAIL,
      method: HTTP_METHODS.PUT,
      data,
    });

    return res;
  };

  logout = async () => {
    await this.httpService.request({
      url: ROUTES.LOGOUT,
      method: HTTP_METHODS.POST,
    });

    this.destroySession();
  };

  forgotPassword = async (data) => {
    const res = await this.httpService.request({
      url: ROUTES.FORGOT_PASSWORD,
      method: HTTP_METHODS.POST,
      data,
    });

    return res;
  };

  resetPassword = async (data) => {
    const res = await this.httpService.request({
      url: ROUTES.RESET_PASSWORD,
      method: HTTP_METHODS.POST,
      data,
    });

    return res;
  };

  getUser = async () => {
    const res = await this.httpService.request({
      url: ROUTES.ME,
      method: HTTP_METHODS.GET,
    });

    return res;
  };

  destroySession = () => {
    removeItem('token');
    this.httpService.removeHeaders(['Authorization']);
  };

  checkTokenExpiration = async (request) => {
    if (request.url === ROUTES.TOKEN_REFRESH) {
      return request;
    }

    const token = this.getAccessToken();

    if (helperService.hasTokenExpired(token)) {
      // const newToken = await this.refreshToken(token);
      // request.headers.Authorization = `Bearer ${newToken}`;
      //
      // return request;
      await this.logout();
    }

    return request;
  };

  decodeJwt = async (token) => {
    return jwtDecode(token);
  };

  handleSuccessResponse = (response) => {
    return response;
  };

  handleErrorResponse = (error) => {
    try {
      const { status } = error.response;

      /* eslint-disable default-case */
      switch (status) {
        case HTTP_STATUS_CODES.UNAUTHORIZED:
          this.destroySession();
          // window.location = '/login';
          break;
      }

      return Promise.reject(error);
    } catch (e) {
      return Promise.reject(error);
    }
  };
}

const authService = new AuthService(httpService);

export default authService;

import { createSelector } from 'reselect';
import { initialState } from './reducer';

const errorHandlerStateSelector = (state) => state.errorHandler || initialState;

const errorHandlerInitializedSelector = () =>
  createSelector(
    errorHandlerStateSelector,
    (state) => state.errorHandlerInitialized
  );

export { errorHandlerInitializedSelector };

import httpService from './HttpService';
import { HTTP_METHODS } from 'consts';

const ROUTES = {
  GET_GROUPS: '/groups',
  GET_GROUP: '/groups/:groupId',
};

class GroupService {
  constructor(httpService) {
    this.httpService = httpService;
  }

  getGroups = () => {
    return this.httpService.request({
      url: ROUTES.GET_GROUPS,
      method: HTTP_METHODS.GET,
    });
  };

  getGroup = (groupId) => {
    return this.httpService.request({
      url: ROUTES.GET_GROUP.replace(':groupId', groupId),
      method: HTTP_METHODS.GET,
    });
  };
}

const groupService = new GroupService(httpService);

export default groupService;

import { takeLatest, call, put } from 'redux-saga/effects';
import {
  GET_WORKFLOW_SAGA,
  REDIRECT_TO_WORKFLOW_SAGA,
  SUBMIT_ATTEMPT_SAGA,
} from './actionTypes';
import { setLoader } from '../loader/actions';
import workflowService from '../../services/WorkflowService';
import authService from '../../services/AuthService';
import { setToken, setUser } from '../auth/actions';
import { push } from 'connected-react-router';
import { SINGLE_WORKFLOW } from '../../routes';
import { setItem } from '../../utils/localStorage';
import {
  setTestResultsData,
  setWorkflow,
  setWorkflowResultData,
} from './actions';

export function* submitAttemptSaga({ payload }) {
  try {
    yield put(setLoader(true));
    const testResultsData = yield call(workflowService.submitAttempt, payload);
    if (testResultsData.test_results) {
      if (payload.lastStep) {
        const lastTestResultsData = {
          test_results: testResultsData.test_results.filter(
            (test_result) => test_result.test.id === payload.testId
          ),
        };
        yield put(setTestResultsData(lastTestResultsData));
        yield put(setWorkflowResultData(testResultsData));
      } else {
        yield put(setTestResultsData(testResultsData));
      }
    } else {
      yield put(setTestResultsData(null));
    }
  } catch (error) {
    console.log({ error });
  } finally {
    yield put(setLoader(false));
  }
}

export function* redirectToWorkflowSaga({ classesId, journeyId, token }) {
  try {
    yield put(setLoader(true));
    yield call(authService.setAuthToken, token);
    const user = yield call(authService.getUser);
    yield put(setUser(user));
    yield put(setToken(token));
    setItem('isAppBarHidden', true);
    yield put(
      push(
        SINGLE_WORKFLOW.replace(':classesId', classesId).replace(
          ':workflowId',
          journeyId
        )
      )
    );
  } catch (error) {
    console.log({ error });
  } finally {
    yield put(setLoader(false));
  }
}

export function* getWorkflowSaga({ groupId, workflowId }) {
  try {
    yield put(setLoader(true));
    const workflow = yield call(
      workflowService.getWorkflow,
      groupId,
      workflowId
    );
    yield put(setWorkflow(workflow));
  } catch (error) {
    console.log({ error });
  } finally {
    yield put(setLoader(false));
  }
}

export default function* workflowSaga() {
  yield takeLatest(SUBMIT_ATTEMPT_SAGA, submitAttemptSaga);
  yield takeLatest(REDIRECT_TO_WORKFLOW_SAGA, redirectToWorkflowSaga);
  yield takeLatest(GET_WORKFLOW_SAGA, getWorkflowSaga);
}

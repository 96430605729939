import httpService from './HttpService';
import { HTTP_METHODS } from 'consts';

const ROUTES = {
  GET_WORKFLOW: '/workflows/group/:groupId/workflow/:workflowId',
  SUBMIT_ATTEMPT: '/workflows',
};

class WorkflowService {
  constructor(httpService) {
    this.httpService = httpService;
  }

  submitAttempt = (data) => {
    return this.httpService.request({
      url: ROUTES.SUBMIT_ATTEMPT,
      method: HTTP_METHODS.POST,
      data,
    });
  };

  getWorkflow = (groupId, workflowId) => {
    return this.httpService.request({
      url: ROUTES.GET_WORKFLOW.replace(':groupId', groupId).replace(
        ':workflowId',
        workflowId
      ),
      method: HTTP_METHODS.GET,
    });
  };
}

const workflowService = new WorkflowService(httpService);

export default workflowService;

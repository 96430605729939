export const AUTH = '[Auth]';

export const LOGIN_SAGA = `${AUTH} LOGIN_SAGA`;
export const LOGOUT_SAGA = `${AUTH} LOGOUT_SAGA`;
export const RESET_PASSWORD_SAGA = `${AUTH} RESET_PASSWORD_SAGA`;
export const SET_TOKEN = `${AUTH} SET_TOKEN`;
export const GET_USER_SAGA = `${AUTH} GET_USER_SAGA`;
export const SET_USER = `${AUTH} SET_USER`;
export const REDIRECT_AFTER_PASSWORD_RESET_SAGA = `${AUTH} REDIRECT_AFTER_PASSWORD_RESET_SAGA`;
export const REGISTER_SAGA = `${AUTH} REGISTER_SAGA`;

import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Routes from './Routes';
import { getUserSaga, logoutSaga } from 'store/auth/actions';
import { tokenSelector, userSelector } from 'store/auth/selectors';
import styles from '../App/App.module.scss';
import SnackbarComponent from '../../components/Snackbar/SnackbarComponent';
import Loader from '../Loader';
import { errorHandlerInitializedSelector } from '../../store/errorHandler/selectors';
import AppBarComponent from 'components/AppBar/AppBarComponent';
import { getItem } from '../../utils/localStorage';

function App() {
  const dispatch = useDispatch();

  const token = useSelector(tokenSelector());
  const errorHandlerInitialized = useSelector(
    errorHandlerInitializedSelector()
  );
  const user = useSelector(userSelector());

  const isAppBarHidden = getItem('isAppBarHidden');

  const handleLogout = useCallback(() => dispatch(logoutSaga()), [dispatch]);

  useEffect(() => {
    if (errorHandlerInitialized) {
      if (token) {
        dispatch(getUserSaga());
      }
    }
  }, [token, dispatch, errorHandlerInitialized]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>Finance411 User</title>
      </Helmet>
      {user && !isAppBarHidden && (
        <AppBarComponent user={user} onLogout={handleLogout} />
      )}
      <div className={styles.container}>
        <Routes />
      </div>
      <Loader />
      <SnackbarComponent />
    </HelmetProvider>
  );
}

export default App;

import { takeLatest, call, put } from 'redux-saga/effects';
import { GET_GROUP_SAGA, GET_GROUPS_SAGA } from './actionTypes';
import { setLoader } from '../loader/actions';
import groupService from '../../services/GroupService';
import { setGroup, setGroups } from './actions';

export function* getGroupsSaga() {
  try {
    yield put(setLoader(true));
    const groups = yield call(groupService.getGroups);
    yield put(setGroups(groups));
  } catch (error) {
    console.log({ error });
  } finally {
    yield put(setLoader(false));
  }
}

export function* getGroupSaga({ groupId }) {
  try {
    yield put(setLoader(true));
    const group = yield call(groupService.getGroup, groupId);
    yield put(setGroup(group));
  } catch (error) {
    console.log({ error });
  } finally {
    yield put(setLoader(false));
  }
}

export default function* groupSaga() {
  yield takeLatest(GET_GROUPS_SAGA, getGroupsSaga);
  yield takeLatest(GET_GROUP_SAGA, getGroupSaga);
}

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import history from 'utils/history';
import authReducer from './auth/reducer';
import snackbarReducer from './snackbar/reducer';
import loaderReducer from './loader/reducer';
import errorHandlerReducer from './errorHandler/reducer';
import groupReducer from './group/reducer';
import workflowReducer from './workflow/reducer';

export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    snackbar: snackbarReducer,
    loader: loaderReducer,
    errorHandler: errorHandlerReducer,
    group: groupReducer,
    workflow: workflowReducer,
    ...injectedReducers,
  });

  return rootReducer;
}

import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { isAuthenticatedSelector } from 'store/auth/selectors';
import { REDIRECT_TO_WORKFLOW } from '../../routes';

export function PublicRoute({
  component: Component,
  isAuthenticated,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) => {
        return isAuthenticated && props.match.path !== REDIRECT_TO_WORKFLOW ? (
          <Redirect to="/home" />
        ) : (
          <Component {...props} />
        );
      }}
    />
  );
}

PublicRoute.propTypes = {
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  isAuthenticated: isAuthenticatedSelector(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(PublicRoute);

export const getQueryParam = (search, param) => {
  const searchParams = new URLSearchParams(search);

  return searchParams.get(param);
};

export const getErrorMessage = (error) => {
  if (error?.data?.message) {
    return error?.data?.message;
  } else if (error?.message) {
    return error?.message;
  } else if (error?.data) {
    return error?.data;
  } else {
    return 'Something went wrong!';
  }
};

export const openLink = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

export const fractionToPercentage = (numerator, denominator) => {
  return `${Math.round((numerator / denominator) * 100)}%`;
};

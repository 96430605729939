export const DASHBOARD = '/home';
export const LOGIN = '/login';
export const VERIFY_ACCOUNT = '/user-verification';
export const REGISTER = '/register/:invite_code';
export const FORGOT_PASSWORD = '/forgot-password';
export const RESET_PASSWORD = '/reset-password';
export const GROUP_LIST = '/classes';
export const SINGLE_GROUP = '/classes/:classesId';
export const SINGLE_WORKFLOW = '/classes/:classesId/workflow/:workflowId';
export const REDIRECT_TO_WORKFLOW = '/redirect-to-journey';

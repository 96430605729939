/* eslint-disable no-useless-escape */
import jwtDecode from 'jwt-decode';

class HelperService {
  camel2title = (camelCase) =>
    camelCase
      .replace(/([A-Z])/g, (match) => ` ${match}`)
      .replace(/^./, (match) => match?.toUpperCase())
      .trim();

  titleCase = (str) => {
    let strAr = str.toLowerCase().split(' ');
    for (let i = 0; i < strAr.length; i++) {
      strAr[i] = strAr[i].charAt(0).toUpperCase() + strAr[i].slice(1);
    }
    return strAr.join(' ');
  };

  // stringToTitleCase(s) {
  //   return s
  //     .replace(/([^A-Z])([A-Z])/g, '$1 $2') // split cameCase
  //     .replace(/[_\-]+/g, ' ') // split snake_case and lisp-case
  //     .toLowerCase()
  //     .replace(/(^\w|\b\w)/g, function (m) {
  //       return m.toUpperCase();
  //     }) // title case words
  //     .replace(/\s+/g, ' ') // collapse repeated whitespace
  //     .replace(/^\s+|\s+$/, ''); // remove leading/trailing whitespace
  // }

  snakeCaseToTitleCase(s) {
    return s
      .replace(/[_\-]+/g, ' ') // split snake_case and lisp-case
      .replace(/(^\w|\b\w)/g, function (m) {
        return m.toUpperCase();
      }); // title case words
  }

  checkIsAuthenticated = (array, user) => {
    return array.some((item) => item === user?.role);
  };

  hasTokenExpired = (token) => {
    return token && Date.now() / 1000 >= jwtDecode(token).exp;
  };
}

export const helperService = new HelperService();

import produce from 'immer';

import { SET_GROUP, SET_GROUPS } from './actionTypes';

export const initialState = {
  groups: null,
  group: null,
};

/* eslint-disable default-case */

const groupReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case SET_GROUPS:
        draft.groups = payload;
        break;
      case SET_GROUP:
        draft.group = payload;
        break;
    }
  });

export default groupReducer;

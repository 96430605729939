import { fork } from 'redux-saga/effects';

import AuthSagas from './auth/sagas';
import GroupSagas from './group/sagas';
import WorkflowSagas from './workflow/sagas';

export default function* rootSaga() {
  yield fork(AuthSagas);
  yield fork(GroupSagas);
  yield fork(WorkflowSagas);
}

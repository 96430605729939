import { createSelector } from 'reselect';
import { initialState } from './reducer';

const authStateSelector = (state) => state.auth || initialState;

const tokenSelector = () =>
  createSelector(authStateSelector, (state) => state.token);

const userSelector = () =>
  createSelector(authStateSelector, (state) => state.user);

const isAuthenticatedSelector = () =>
  createSelector(authStateSelector, (state) => !!state.token);

export { tokenSelector, userSelector, isAuthenticatedSelector };

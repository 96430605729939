import {
  LOGIN_SAGA,
  LOGOUT_SAGA,
  SET_TOKEN,
  RESET_PASSWORD_SAGA,
  SET_USER,
  GET_USER_SAGA,
  REDIRECT_AFTER_PASSWORD_RESET_SAGA,
  REGISTER_SAGA,
} from './actionTypes';

export function loginSaga(payload) {
  return {
    type: LOGIN_SAGA,
    payload,
  };
}

export function logoutSaga() {
  return {
    type: LOGOUT_SAGA,
  };
}

export function setToken(token) {
  return {
    type: SET_TOKEN,
    token,
  };
}

export function resetPasswordSaga(payload) {
  return {
    type: RESET_PASSWORD_SAGA,
    payload,
  };
}

export function getUserSaga() {
  return {
    type: GET_USER_SAGA,
  };
}

export function setUser(payload) {
  return {
    type: SET_USER,
    payload,
  };
}

export function redirectAfterPasswordResetSaga(payload) {
  return {
    type: REDIRECT_AFTER_PASSWORD_RESET_SAGA,
    payload,
  };
}

export function registerSaga(payload) {
  return {
    type: REGISTER_SAGA,
    payload,
  };
}

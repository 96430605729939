import {
  GET_WORKFLOW_SAGA,
  REDIRECT_TO_WORKFLOW_SAGA,
  SET_TEST_RESULTS_DATA,
  SET_WORKFLOW,
  SET_WORKFLOW_RESULTS_DATA,
  SUBMIT_ATTEMPT_SAGA,
} from './actionTypes';

export function submitAttemptSaga(payload) {
  return {
    type: SUBMIT_ATTEMPT_SAGA,
    payload,
  };
}

export function redirectToWorkflowSaga(classesId, journeyId, token) {
  return {
    type: REDIRECT_TO_WORKFLOW_SAGA,
    classesId,
    journeyId,
    token,
  };
}

export function setTestResultsData(payload) {
  return {
    type: SET_TEST_RESULTS_DATA,
    payload,
  };
}

export function setWorkflowResultData(payload) {
  return {
    type: SET_WORKFLOW_RESULTS_DATA,
    payload,
  };
}

export function getWorkflowSaga(groupId, workflowId) {
  return {
    type: GET_WORKFLOW_SAGA,
    groupId,
    workflowId,
  };
}

export function setWorkflow(payload) {
  return {
    type: SET_WORKFLOW,
    payload,
  };
}

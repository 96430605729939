import produce from 'immer';
import { getItem } from 'utils/localStorage';
import { SET_TOKEN, SET_USER } from './actionTypes';

export const initialState = {
  token: getItem('token') || null,
  user: null,
};

/* eslint-disable default-case */
const appReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_TOKEN:
        draft.token = action.token;
        break;
      case SET_USER:
        draft.user = action.payload;
        break;
    }
  });

export default appReducer;

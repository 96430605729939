import { takeLatest, call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { setToken, setUser } from './actions';
import {
  GET_USER_SAGA,
  LOGIN_SAGA,
  LOGOUT_SAGA,
  REDIRECT_AFTER_PASSWORD_RESET_SAGA,
  REGISTER_SAGA,
  RESET_PASSWORD_SAGA,
} from './actionTypes';
import { DASHBOARD, GROUP_LIST, LOGIN } from 'routes';
import authService from 'services/AuthService';
import { removeItem } from '../../utils/localStorage';
import { setSnackbar } from '../snackbar/actions';
import { setLoader } from '../loader/actions';
import { getErrorMessage } from '../../helpers';

export function* loginSaga({ payload }) {
  try {
    yield put(setLoader(true));
    const res = yield call(authService.login, payload);
    yield put(setToken(res.authorization.token));
    yield put(push(DASHBOARD));
  } catch (error) {
    console.log({ error });
  } finally {
    yield put(setLoader(false));
  }
}

export function* logoutSaga() {
  try {
    yield call(removeItem, 'token');
    yield put(setToken(null));
    yield put(setUser(null));
    yield call(authService.destroySession);
    yield put(push(LOGIN));
  } catch (error) {
    console.log({ error });
  }
}

export function* getUserSaga() {
  try {
    yield put(setLoader(true));
    const res = yield call(authService.getUser);
    yield put(setUser(res));
  } catch (error) {
    console.log({ error });
  } finally {
    yield put(setLoader(false));
  }
}

export function* resetPasswordSaga({ payload }) {
  try {
    yield put(setLoader(true));
    yield call(authService.resetPassword, payload);
    yield put(push(LOGIN));
  } catch (error) {
    console.log({ error });
    yield put(
      setSnackbar({
        open: true,
        severity: 'error',
        message: getErrorMessage(error),
      })
    );
  } finally {
    yield put(setLoader(false));
  }
}

export function* redirectAfterPasswordResetSaga({ payload }) {
  try {
    yield put(setLoader(true));
    yield call(authService.setAuthToken, payload.access_token);
    yield put(setToken(payload.access_token));
    if (payload.approved) {
      yield put(push(GROUP_LIST));
    } else {
      yield put(push(DASHBOARD));
    }
  } catch (error) {
    console.log({ error });
  } finally {
    yield put(setLoader(false));
  }
}

export function* registerSaga({ payload }) {
  try {
    yield put(setLoader(true));
    const user = yield call(authService.registerUser, payload);
    yield put(setToken(user.access_token));
    yield put(push(DASHBOARD));
  } catch (error) {
    console.log({ error });
    yield put(
      setSnackbar({
        open: true,
        severity: 'error',
        message: getErrorMessage(error),
      })
    );
  } finally {
    yield put(setLoader(false));
  }
}

export default function* authSaga() {
  yield takeLatest(LOGIN_SAGA, loginSaga);
  yield takeLatest(LOGOUT_SAGA, logoutSaga);
  yield takeLatest(RESET_PASSWORD_SAGA, resetPasswordSaga);
  yield takeLatest(GET_USER_SAGA, getUserSaga);
  yield takeLatest(
    REDIRECT_AFTER_PASSWORD_RESET_SAGA,
    redirectAfterPasswordResetSaga
  );
  yield takeLatest(REGISTER_SAGA, registerSaga);
}
